import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Loading from "./components/LoadingComp/Loading";

const MainLanding = lazy(() =>
  import("./pages/LandingPage/AllTogether/Alltogether")
);
const Blog = lazy(() => import("./pages/Blog/Blog"));
const Privacy = lazy(() => import("./pages/Privacy/Privacy"));
const OurGallery = lazy(() => import("./pages/Gallery/OurGallery"));
const About = lazy(() => import("./pages/About/About"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const Feedback = lazy(() => import("./pages/Feedback/Feedback"));

function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Suspense fallback={<Loading/>}><MainLanding /></Suspense>} />
        <Route path="/blog" element={<Suspense fallback={<Loading/>}><Blog /></Suspense>} />
        <Route path="/privacy" element={<Suspense fallback={<Loading/>}><Privacy /></Suspense>} />
        <Route path="/gallery" element={<Suspense fallback={<Loading/>}><OurGallery /></Suspense>} />
        <Route path="/about" element={<Suspense fallback={<Loading/>}><About /></Suspense>} />
        <Route path="/contact" element={<Suspense fallback={<Loading/>}><Contact /></Suspense>} />
        <Route path="/feedback" element={<Suspense fallback={<Loading/>}><Feedback /></Suspense>} />
      </Routes> 
    </div>
  );
}

export default App;
